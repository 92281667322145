import { PropsWithChildren, useRef, useState } from "react";

type Props = {
  action: () => void;
  id?: string;
};

const CheckBox = ({ action, id }: PropsWithChildren<Props>): JSX.Element => {
  const [checked, setChecked] = useState(false);

  const checkBox = useRef(null);

  const switchState = () => {
    action();

    setChecked(!checked);

    if (checked) {
      checkBox.current.classList.remove("checked");
      checkBox.current.classList.add("empty");
    } else {
      checkBox.current.classList.add("checked");
      checkBox.current.classList.remove("empty");
    }
  };

  return (
    <div className="checkbox-container" onClick={switchState}>
      <div id={id} ref={checkBox} className="checkbox empty"></div>
    </div>
  );
};

export default CheckBox;
