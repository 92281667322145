import * as React from "react";
import * as ReactDOMClient from "react-dom/client";
import "./static/styles/index.css";
import App from "components/App";

const root = ReactDOMClient.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
