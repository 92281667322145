import { PropsWithChildren } from "react";
import { User } from "types";
import UserCard from "./UserCard";

type Props = {
  users: User[];
  action: (user: User) => void;
};

const UserGrid = ({ users, action }: PropsWithChildren<Props>): JSX.Element => {
  return (
    <div id="user-grid">
      {users.map((user) => (
        <UserCard key={user.id} user={user} action={action} />
      ))}
    </div>
  );
};

export default UserGrid;
