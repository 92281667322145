import Logo from "../static/images/logo.png";

const Header = (): JSX.Element => (
  <div id="logo-container" onClick={() => window.location.reload()}>
    <img id="logo" src={Logo} alt="Logo" />
    <h1 id="title">tfinder</h1>
  </div>
);

export default Header;
