import { MouseEvent, useEffect, useRef, useState } from "react";
import Header from "./Header";
import SearchBar from "./SearchBar";
import UserGrid from "./UserGrid";
import DisplayedUser from "./DisplayedUser";
import { User } from "types";

const sun = "🌞";
const moon = "🌚";

const App = (): JSX.Element => {
  const [users, setUsers] = useState([]);
  const [displayedUser, setDisplayedUser] = useState(null);

  const blurUsers = useRef(null);
  const displayedUserRoot = useRef(null);
  const themeSwitchBtn = useRef(null);

  const switchTheme = () => {
    if (window.localStorage.getItem("tfinder-theme") !== "dark") {
      document.body.classList.remove("light");
      document.body.classList.add("dark");
      themeSwitchBtn.current.textContent = sun;
      window.localStorage.setItem("tfinder-theme", "dark");
    } else {
      document.body.classList.remove("dark");
      document.body.classList.add("light");
      themeSwitchBtn.current.textContent = moon;
      window.localStorage.setItem("tfinder-theme", "light");
    }
  };

  const displayUserInfo = (user: User) => {
    let jobTitle = null;
    let company = null;
    let worksAt = null;
    let school = null;

    if (user.jobs?.length > 0) {
      let job = user.jobs[0];

      jobTitle = job.title;
      company = job.company;

      if (jobTitle && company) {
        worksAt = `${jobTitle} at ${company}`;
      } else if (jobTitle) {
        worksAt = jobTitle;
      } else if (company) {
        worksAt = `Works at ${company}`;
      }
    }

    if (user.schools?.length > 0) {
      school = `Studied at ${user.schools[0]}`;
    }

    setDisplayedUser({
      ...user,
      job: worksAt,
      school,
    });

    blurUsers.current.style.display = "flex";
    displayedUserRoot.current.style.display = "flex";

    document.body.style.overflow = "hidden";
  };

  const hideDisplayedUser = (e: MouseEvent) => {
    if (e.target !== e.currentTarget) {
      return;
    }

    blurUsers.current.style.display = "none";
    displayedUserRoot.current.style.display = "none";
    document.body.style.overflow = "scroll";
  };

  useEffect(() => {
    if (window.localStorage.getItem("tfinder-theme") === "dark") {
      document.body.classList.remove("light");
      document.body.classList.add("dark");
      themeSwitchBtn.current.textContent = sun;
    } else {
      themeSwitchBtn.current.textContent = moon;
    }
  });

  return (
    <div id="app-root">
      <Header />
      <SearchBar showResults={setUsers} />
      <UserGrid users={users} action={displayUserInfo} />

      <div id="blur-users" ref={blurUsers} onClick={hideDisplayedUser}></div>

      <div
        id="displayed-user-container"
        ref={displayedUserRoot}
        onClick={hideDisplayedUser}
      >
        <DisplayedUser user={displayedUser} />
      </div>

      <button
        id="theme-switch-btn"
        ref={themeSwitchBtn}
        onClick={switchTheme}
      />
    </div>
  );
};

export default App;
