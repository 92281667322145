import { Buffer } from "buffer";
import { PropsWithChildren, useState } from "react";
import useSWR from "swr";
import Loading from "../static/images/loading.gif";
import { API_URL } from "../constants";

type Props = {
  cid: string;
};

const fetcher = (url: string) => fetch(url).then((res) => res.arrayBuffer());

const UserPhoto = ({ cid }: PropsWithChildren<Props>): JSX.Element => {
  let [image] = useState(Loading);

  const { data, error } = useSWR(`${API_URL}/getPhoto/${cid}`, fetcher);

  if (data && !error) {
    image = "data:image/jpeg;base64," + Buffer.from(data).toString("base64");
  }

  return (
    <div>
      <img className="user-photo" src={image} alt={cid}></img>
    </div>
  );
};

export default UserPhoto;
