import Appetite from "./static/images/descriptors/appetite.png";
import AstrologicalSign from "./static/images/descriptors/astrological_sign.png";
import CommunicationStyle from "./static/images/descriptors/communication_style.png";
import Covid from "./static/images/descriptors/covid.png";
import DrinkOfChoice from "./static/images/descriptors/drink_of_choice.png";
import Education from "./static/images/descriptors/education.png";
import Kids from "./static/images/descriptors/kids.png";
import LoveLanguage from "./static/images/descriptors/love_language.png";
import Mbti from "./static/images/descriptors/mbti.png";
import Pets from "./static/images/descriptors/pets.png";
import Smoking from "./static/images/descriptors/smoking.png";
import SleepingHabits from "./static/images/descriptors/sleeping_habits.png";
import SocialMedia from "./static/images/descriptors/social_media.png";

const API_URL = (() => {
  const value = process.env.REACT_APP_TFINDER_BASE_URL;
  const postfix = "/undefined";

  if (value.endsWith(postfix)) {
    return value.substring(0, value.length - postfix.length);
  }

  return value;
})();

const interests = [
  { id: "it_2275", name: "Harry Potter" },
  { id: "it_2033", name: "90s Kid" },
  { id: "it_2396", name: "SoundCloud" },
  { id: "it_2397", name: "Spa" },
  { id: "it_2155", name: "Self Care" },
  { id: "it_2276", name: "Heavy Metal" },
  { id: "it_2031", name: "House Parties" },
  { id: "it_2152", name: "Gin tonic" },
  { id: "it_2273", name: "Gymnastics" },
  { id: "it_2279", name: "Hot Yoga" },
  { id: "it_2159", name: "Meditation" },
  { id: "it_2398", name: "Spotify" },
  { id: "it_2035", name: "Sushi" },
  { id: "it_2277", name: "Hockey" },
  { id: "it_2156", name: "Basketball" },
  { id: "it_2036", name: "Slam Poetry" },
  { id: "it_2278", name: "Home Workout" },
  { id: "it_2157", name: "Theater" },
  { id: "it_33", name: "Cafe hopping" },
  { id: "it_36", name: "Aquarium" },
  { id: "it_2039", name: "Sneakers" },
  { id: "it_35", name: "Instagram" },
  { id: "it_30", name: "Hot Springs" },
  { id: "it_31", name: "Walking" },
  { id: "it_4", name: "Running" },
  { id: "it_7", name: "Travel" },
  { id: "it_6", name: "Language Exchange" },
  { id: "it_9", name: "Movies" },
  { id: "it_2271", name: "Guitarists" },
  { id: "it_2392", name: "Social Development" },
  { id: "it_2272", name: "Gym" },
  { id: "it_2393", name: "Social Media" },
  { id: "it_2030", name: "Hip Hop" },
  { id: "it_2390", name: "Skincare" },
  { id: "it_2022", name: "J-Pop" },
  { id: "it_2386", name: "Shisha" },
  { id: "it_2023", name: "Cricket" },
  { id: "it_2262", name: "Freelance" },
  { id: "it_2389", name: "Skateboarding" },
  { id: "it_2268", name: "Gospel" },
  { id: "it_27", name: "K-Pop" },
  { id: "it_2027", name: "Potterhead" },
  { id: "it_26", name: "Trying New Things" },
  { id: "it_29", name: "Photography" },
  { id: "it_2024", name: "Bollywood" },
  { id: "it_28", name: "Reading" },
  { id: "it_2388", name: "Singing" },
  { id: "it_23", name: "Sports" },
  { id: "it_2028", name: "Poetry" },
  { id: "it_2029", name: "Stand up Comedy" },
  { id: "it_1", name: "Coffee" },
  { id: "it_3", name: "Karaoke" },
  { id: "it_2260", name: "Fortnite" },
  { id: "it_2261", name: "Free Diving" },
  { id: "it_2382", name: "Self Development" },
  { id: "it_2055", name: "Mental Health Awareness" },
  { id: "it_19", name: "Foodie Tour" },
  { id: "it_2053", name: "Voter Rights" },
  { id: "it_2295", name: "Jiu-jitsu" },
  { id: "it_2054", name: "Climate Change" },
  { id: "it_16", name: "Exhibition" },
  { id: "it_15", name: "Walking My Dog" },
  { id: "it_2057", name: "LGBTQ+ Rights" },
  { id: "it_2058", name: "Feminism" },
  { id: "it_12", name: "VR Room" },
  { id: "it_11", name: "Escape Cafe" },
  { id: "it_14", name: "Shopping" },
  { id: "it_10", name: "Brunch" },
  { id: "it_2290", name: "Investment" },
  { id: "it_2293", name: "Jetski" },
  { id: "it_2172", name: "Reggaeton" },
  { id: "it_2051", name: "Second-hand apparel" },
  { id: "it_2052", name: "Black Lives Matter" },
  { id: "it_2294", name: "Jogging" },
  { id: "it_2050", name: "Road Trips" },
  { id: "it_2171", name: "Vintage fashion" },
  { id: "it_2165", name: "Voguing" },
  { id: "it_2166", name: "Couchsurfing" },
  { id: "it_2163", name: "Happy hour" },
  { id: "it_2285", name: "Inclusivity" },
  { id: "it_2048", name: "Country Music" },
  { id: "it_2049", name: "Football" },
  { id: "it_2288", name: "Inline Skate" },
  { id: "it_2289", name: "Investing" },
  { id: "it_2161", name: "Tennis" },
  { id: "it_2282", name: "Ice Cream" },
  { id: "it_2283", name: "Ice Skating" },
  { id: "it_2280", name: "Human Rights" },
  { id: "it_2160", name: "Expositions" },
  { id: "it_2352", name: "Pig Roast" },
  { id: "it_1021", name: "Skiing" },
  { id: "it_2232", name: "Canoeing" },
  { id: "it_2111", name: "West End Musicals" },
  { id: "it_1022", name: "Snowboarding" },
  { id: "it_2353", name: "Pilates" },
  { id: "it_2351", name: "Pentathlon" },
  { id: "it_2230", name: "Broadway" },
  { id: "it_2356", name: "PlayStation" },
  { id: "it_2235", name: "Cheerleading" },
  { id: "it_2236", name: "Choir" },
  { id: "it_2357", name: "Pole Dancing" },
  { id: "it_2115", name: "Five-a-side Football" },
  { id: "it_2233", name: "Car Racing" },
  { id: "it_2354", name: "Pinterest" },
  { id: "it_2113", name: "Pub Quiz" },
  { id: "it_1024", name: "Festivals" },
  { id: "it_2234", name: "Catan" },
  { id: "it_2239", name: "Cosplay" },
  { id: "it_2119", name: "Motor Sports" },
  { id: "it_2237", name: "Coffee Stall" },
  { id: "it_2238", name: "Content Creation" },
  { id: "it_2117", name: "E-Sports" },
  { id: "it_2220", name: "Bicycle Racing" },
  { id: "it_2221", name: "Binge-Watching TV shows" },
  { id: "it_1011", name: "Songwriter" },
  { id: "it_2224", name: "Bodycombat" },
  { id: "it_1014", name: "Tattoos" },
  { id: "it_2346", name: "Painting" },
  { id: "it_2225", name: "Bodyjam" },
  { id: "it_2343", name: "Paddle Boarding" },
  { id: "it_2344", name: "Padel" },
  { id: "it_2223", name: "Blackpink" },
  { id: "it_1013", name: "Surfing" },
  { id: "it_2228", name: "Bowling" },
  { id: "it_2107", name: "Grime" },
  { id: "it_2108", name: "90s Britpop" },
  { id: "it_2226", name: "Bodypump" },
  { id: "it_2106", name: "Beach Bars" },
  { id: "it_2227", name: "Bodystep" },
  { id: "it_2348", name: "Paragliding" },
  { id: "it_2109", name: "Upcycling" },
  { id: "it_2253", name: "Equality" },
  { id: "it_2011", name: "Astrology" },
  { id: "it_2133", name: "Motorcycles" },
  { id: "it_2254", name: "Equestrian" },
  { id: "it_2251", name: "Entrepreneurship" },
  { id: "it_2372", name: "Sake" },
  { id: "it_2131", name: "BTS" },
  { id: "it_2010", name: "Cooking" },
  { id: "it_2252", name: "Environmental Protection" },
  { id: "it_2257", name: "Fencing" },
  { id: "it_2015", name: "Soccer" },
  { id: "it_2378", name: "Saxophonist" },
  { id: "it_2379", name: "Sci-Fi" },
  { id: "it_2016", name: "Dancing" },
  { id: "it_2258", name: "Film Festival" },
  { id: "it_2013", name: "Gardening" },
  { id: "it_2135", name: "Amateur Cook" },
  { id: "it_2256", name: "Exchange Program" },
  { id: "it_2377", name: "Sauna" },
  { id: "it_2014", name: "Art" },
  { id: "it_2019", name: "Politics" },
  { id: "it_2259", name: "Flamenco" },
  { id: "it_2017", name: "Museum" },
  { id: "it_2018", name: "Activism" },
  { id: "it_2242", name: "DAOs" },
  { id: "it_2363", name: "Real Estate" },
  { id: "it_2121", name: "Podcasts" },
  { id: "it_2243", name: "Disability Rights" },
  { id: "it_2362", name: "Rave" },
  { id: "it_2120", name: "Pimms" },
  { id: "it_2246", name: "Drive Thru Cinema" },
  { id: "it_2367", name: "Rock Climbing" },
  { id: "it_2125", name: "BBQ" },
  { id: "it_2004", name: "Craft Beer" },
  { id: "it_2126", name: "Iced Tea" },
  { id: "it_2247", name: "Drummer" },
  { id: "it_2005", name: "Tea" },
  { id: "it_2002", name: "Board Games" },
  { id: "it_2365", name: "Roblox" },
  { id: "it_2123", name: "Pubs" },
  { id: "it_2366", name: "Rock" },
  { id: "it_2124", name: "Tango" },
  { id: "it_2245", name: "Drawing" },
  { id: "it_2003", name: "Trivia" },
  { id: "it_2129", name: "Pho" },
  { id: "it_2008", name: "Volunteering" },
  { id: "it_2009", name: "Environmentalism" },
  { id: "it_2369", name: "Rollerskating" },
  { id: "it_2006", name: "Wine" },
  { id: "it_2248", name: "Dungeons & Dragons" },
  { id: "it_2007", name: "Vlogging" },
  { id: "it_2249", name: "Electronic Music" },
  { id: "it_2360", name: "Ramen" },
  { id: "it_2430", name: "Weightlifting" },
  { id: "it_2312", name: "Live Music" },
  { id: "it_2433", name: "Writing" },
  { id: "it_2434", name: "Xbox" },
  { id: "it_2431", name: "World Peace" },
  { id: "it_2432", name: "Wrestling" },
  { id: "it_2311", name: "Literature" },
  { id: "it_2316", name: "Manga" },
  { id: "it_2437", name: "Pride" },
  { id: "it_2317", name: "Marathon" },
  { id: "it_2314", name: "Makeup" },
  { id: "it_2435", name: "Youth Empowerment" },
  { id: "it_2436", name: "YouTube" },
  { id: "it_2318", name: "Martial Arts" },
  { id: "it_2319", name: "Marvel" },
  { id: "it_2422", name: "Vegan Cooking" },
  { id: "it_2423", name: "Vermut" },
  { id: "it_2302", name: "Korean Food" },
  { id: "it_2420", name: "Twitter" },
  { id: "it_2426", name: "Volleyball" },
  { id: "it_2427", name: "Walking Tour" },
  { id: "it_2424", name: "Vinyasa" },
  { id: "it_2425", name: "Virtual Reality" },
  { id: "it_2309", name: "League of Legends" },
  { id: "it_2334", name: "NFTs" },
  { id: "it_2213", name: "Bar Hopping" },
  { id: "it_2335", name: "Nintendo" },
  { id: "it_2214", name: "Baseball" },
  { id: "it_1001", name: "Parties" },
  { id: "it_2211", name: "Ballet" },
  { id: "it_2212", name: "Band" },
  { id: "it_2338", name: "Online Games" },
  { id: "it_2217", name: "Battle Ground" },
  { id: "it_2218", name: "Beach Tennis" },
  { id: "it_99", name: "Nightlife" },
  { id: "it_2339", name: "Online Shopping" },
  { id: "it_1005", name: "Sailing" },
  { id: "it_2336", name: "Olympic Gymnastics" },
  { id: "it_2215", name: "Bassist" },
  { id: "it_2337", name: "Online Broker" },
  { id: "it_94", name: "Military" },
  { id: "it_2320", name: "Memes" },
  { id: "it_2202", name: "Among Us" },
  { id: "it_2323", name: "Motorbike Racing" },
  { id: "it_2324", name: "Motorcycling" },
  { id: "it_2321", name: "Metaverse" },
  { id: "it_2322", name: "Mindfulness" },
  { id: "it_2201", name: "Acapella" },
  { id: "it_2327", name: "Musical Instrument" },
  { id: "it_2206", name: "Art Galleries" },
  { id: "it_2328", name: "Musical Writing" },
  { id: "it_88", name: "Hiking" },
  { id: "it_2207", name: "Artistic Gymnastics" },
  { id: "it_2325", name: "Mountains" },
  { id: "it_2205", name: "Archery" },
  { id: "it_2208", name: "Atari" },
  { id: "it_2209", name: "Backpacking" },
  { id: "it_86", name: "Fishing" },
  { id: "it_2075", name: "Clubbing" },
  { id: "it_2079", name: "Street Food" },
  { id: "it_78", name: "Crossfit" },
  { id: "it_76", name: "Concerts" },
  { id: "it_75", name: "Climbing" },
  { id: "it_70", name: "Baking" },
  { id: "it_72", name: "Camping" },
  { id: "it_71", name: "Blogging" },
  { id: "it_2070", name: "Collecting" },
  { id: "it_2072", name: "Cars" },
  { id: "it_2066", name: "Start ups" },
  { id: "it_2067", name: "Boba tea" },
  { id: "it_2064", name: "High School Sports" },
  { id: "it_2069", name: "Badminton" },
  { id: "it_66", name: "Active Lifestyle" },
  { id: "it_63", name: "Fashion" },
  { id: "it_62", name: "Anime" },
  { id: "it_2062", name: "NBA" },
  { id: "it_2063", name: "MLB" },
  { id: "it_2099", name: "Funk music" },
  { id: "it_2097", name: "Caipirinha" },
  { id: "it_59", name: "Indoor Activities" },
  { id: "it_2410", name: "Tempeh" },
  { id: "it_56", name: "DIY" },
  { id: "it_2416", name: "Town Festivities" },
  { id: "it_55", name: "Cycling" },
  { id: "it_58", name: "Outdoors" },
  { id: "it_2414", name: "TikTok" },
  { id: "it_57", name: "Picnicking" },
  { id: "it_2419", name: "Twitch" },
  { id: "it_51", name: "Comedy" },
  { id: "it_2417", name: "Trap Music" },
  { id: "it_54", name: "Music" },
  { id: "it_2418", name: "Triathlon" },
  { id: "it_53", name: "Netflix" },
  { id: "it_50", name: "Disney" },
  { id: "it_2090", name: "Rugby" },
  { id: "it_2095", name: "Açaí" },
  { id: "it_2093", name: "Samba" },
  { id: "it_2094", name: "Tarot" },
  { id: "it_2400", name: "Stock Exchange" },
  { id: "it_2401", name: "Stocks" },
  { id: "it_48", name: "Swimming" },
  { id: "it_2404", name: "Table Tennis" },
  { id: "it_41", name: "Killing time" },
  { id: "it_43", name: "Working out" },
  { id: "it_42", name: "Yoga" },
  { id: "it_2080", name: "Horror Movies" },
  { id: "it_2081", name: "Boxing" },
  { id: "it_2082", name: "Bar Chilling" },
];

const descriptors = [
  {
    id: "de_1",
    name: "Zodiac",
    prompt: "What is your zodiac sign?",
    type: "single_selection_set",
    icon: AstrologicalSign,
    choices: [
      { id: "1", name: "Capricorn" },
      { id: "2", name: "Aquarius" },
      { id: "3", name: "Pisces" },
      { id: "4", name: "Aries" },
      { id: "5", name: "Taurus" },
      { id: "6", name: "Gemini" },
      { id: "7", name: "Cancer" },
      { id: "8", name: "Leo" },
      { id: "9", name: "Virgo" },
      { id: "10", name: "Libra" },
      { id: "11", name: "Scorpio" },
      { id: "12", name: "Sagittarius" },
    ],
  },
  {
    id: "de_9",
    name: "Education",
    prompt: "What is your education level?",
    type: "single_selection_set",
    icon: Education,
    choices: [
      { id: "1", name: "Bachelors" },
      { id: "2", name: "In College" },
      { id: "3", name: "High School" },
      { id: "4", name: "PhD" },
      { id: "5", name: "In Grad School" },
      { id: "6", name: "Masters" },
      { id: "7", name: "Trade School" },
    ],
  },
  {
    id: "de_33",
    name: "Family Plans",
    prompt: "Do you want children?",
    type: "single_selection_set",
    icon: Kids,
    choices: [
      { id: "1", name: "I want children" },
      { id: "2", name: "I don't want children" },
      { id: "3", name: "I have children and want more" },
      { id: "4", name: "I have children and don't want more" },
      { id: "5", name: "Not sure yet" },
    ],
  },
  {
    id: "de_34",
    name: "COVID Vaccine",
    prompt: "Are you vaccinated?",
    type: "single_selection_set",
    icon: Covid,
    choices: [
      { id: "1", name: "Vaccinated" },
      { id: "2", name: "Unvaccinated" },
      { id: "3", name: "Prefer not to say" },
    ],
  },
  {
    id: "de_13",
    name: "Personality Type",
    prompt: "What’s your Personality Type?",
    type: "single_selection_set",
    icon: Mbti,
    choices: [
      { id: "1", name: "INTJ" },
      { id: "2", name: "INTP" },
      { id: "3", name: "ENTJ" },
      { id: "4", name: "ENTP" },
      { id: "5", name: "INFJ" },
      { id: "6", name: "INFP" },
      { id: "7", name: "ENFJ" },
      { id: "8", name: "ENFP" },
      { id: "9", name: "ISTJ" },
      { id: "10", name: "ISFJ" },
      { id: "11", name: "ESTJ" },
      { id: "12", name: "ESFJ" },
      { id: "13", name: "ISTP" },
      { id: "14", name: "ISFP" },
      { id: "15", name: "ESTP" },
      { id: "16", name: "ESFP" },
    ],
  },
  {
    id: "de_2",
    name: "Communication Style",
    prompt: "What is your communication style?",
    type: "single_selection_set",
    icon: CommunicationStyle,
    choices: [
      { id: "1", name: "Big time texter" },
      { id: "3", name: "Phone caller" },
      { id: "2", name: "Video chatter" },
      { id: "4", name: "Bad texter" },
      { id: "5", name: "Better in person" },
    ],
  },
  {
    id: "de_35",
    name: "Love Style",
    prompt: "How do you receive love?",
    type: "single_selection_set",
    icon: LoveLanguage,
    choices: [
      { id: "1", name: "Thoughtful gestures" },
      { id: "2", name: "Presents" },
      { id: "3", name: "Touch" },
      { id: "4", name: "Compliments" },
      { id: "5", name: "Time together" },
    ],
  },
  {
    id: "de_3",
    name: "Pets",
    prompt: "Do you have any pets?",
    type: "single_selection_set",
    icon: Pets,
    choices: [
      { id: "1", name: "Dog" },
      { id: "2", name: "Cat" },
      { id: "3", name: "Reptile" },
      { id: "4", name: "Amphibian" },
      { id: "8", name: "Bird" },
      { id: "5", name: "Fish" },
      { id: "9", name: "Don't have but love" },
      { id: "11", name: "Other" },
      { id: "12", name: "Turtle" },
      { id: "13", name: "Hamster" },
      { id: "14", name: "Rabbit" },
      { id: "6", name: "Pet-free" },
      { id: "7", name: "All the pets" },
      { id: "16", name: "Want a pet" },
      { id: "17", name: "Allergic to pets" },
    ],
  },
  {
    id: "de_22",
    name: "Drinking",
    prompt: "How often do you drink?",
    type: "single_selection_set",
    icon: DrinkOfChoice,
    choices: [
      { id: "8", name: "Not for me" },
      { id: "9", name: "Sober" },
      { id: "10", name: "Sober curious" },
      { id: "11", name: "On special occasions" },
      { id: "12", name: "Socially on weekends" },
      { id: "13", name: "Most Nights" },
    ],
  },
  {
    id: "de_11",
    name: "Smoking",
    prompt: "How often do you smoke?",
    type: "single_selection_set",
    icon: Smoking,
    choices: [
      { id: "1", name: "Social smoker" },
      { id: "2", name: "Smoker when drinking" },
      { id: "3", name: "Non-smoker" },
      { id: "4", name: "Smoker" },
      { id: "6", name: "Trying to quit" },
    ],
  },
  {
    id: "de_7",
    name: "Dietary Preference",
    prompt: "What are your dietary preferences?",
    type: "single_selection_set",
    icon: Appetite,
    choices: [
      { id: "1", name: "Vegan" },
      { id: "2", name: "Vegetarian" },
      { id: "3", name: "Pescatarian" },
      { id: "9", name: "Kosher" },
      { id: "10", name: "Halal" },
      { id: "7", name: "Carnivore" },
      { id: "8", name: "Omnivore" },
      { id: "12", name: "Other" },
    ],
  },
  {
    id: "de_4",
    name: "Social Media",
    prompt: "How active are you on social media?",
    type: "single_selection_set",
    icon: SocialMedia,
    choices: [
      { id: "1", name: "Influencer status" },
      { id: "2", name: "Socially active" },
      { id: "3", name: "Off the grid" },
      { id: "4", name: "Passive scroller" },
    ],
  },
  {
    id: "de_17",
    name: "Sleeping Habits",
    prompt: "What are your sleeping habits?",
    type: "single_selection_set",
    icon: SleepingHabits,
    choices: [
      { id: "1", name: "Early bird" },
      { id: "2", name: "Night owl" },
      { id: "3", name: "In a spectrum" },
    ],
  },
];

const logics = [
  { value: "AND", name: "All of the requirements" },
  { value: "OR", name: "At least one of the requirements" },
  { value: "NAND", name: "Not all of the requirements" },
  { value: "NOR", name: "None of the requirements" },
  { value: "XOR", name: "Only one requirement" },
  { value: "CUSTOM", name: "Custom" },
];

export { API_URL, interests, descriptors, logics };
