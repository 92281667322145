enum Platform {
  Tinder,
  Badoo,
  Bumble,
  Boo,
}

type Photo = {
  file_name: string;
  cid?: string;
};

type Job = {
  title?: string;
  company?: string;
};

type Descriptor = {
  id: string;
  choice?: string;
  other?: string;
};

type Song = {
  title: string;
  artists: string[];
};

type User = {
  platform: Platform;
  id: string;
  name: string;
  bio?: string;
  birth_date?: string;
  verified: boolean;
  photos: Photo[];
  instagram?: Photo[];
  city?: string;
  schools?: string[];
  jobs?: Job[];
  interests?: string[];
  descriptors?: Descriptor[];
  fav_song?: Song;
  fav_artists?: string[];

  // Helpers
  age?: number;
  job?: string;
  school?: string;
};

enum Requirement {
  Platform,
  Name,
  Verified,
  Instagram,
  Age,
  Bio,
  City,
  School,
  Workplace,
  JobTitle,
  FavouriteSong,
  FavouriteArtists,
  Interests,
  Descriptors,
}

type FilterByReqBody = {
  requirements: Requirement[];
  logic: string;
  scheme: {
    platforms?: Platform[];
    age?: number;
    bio?: string;
    name?: string;
    distance?: number;
    city?: string;
    schools: string[];
    workplaces: string[];
    job_titles: string[];
    song: string;
    artists: string[];
    interests: string[];
    descriptors: string[][];
  };
};

export { Platform, type User, Requirement, type FilterByReqBody };
