import { User } from "types";
import { PropsWithChildren } from "react";
import UserPhoto from "./UserPhoto";

type Props = {
  user: User;
  action: (user: User) => void;
};

const UserCard = ({ user, action }: PropsWithChildren<Props>): JSX.Element => {
  return (
    <div className="user" onClick={() => action(user)}>
      <h3 className="user-header">
        {user.name}
        <span className="user-age"> {user.age}</span>
      </h3>
      <UserPhoto cid={user.photos[0].file_name} />
    </div>
  );
};

export default UserCard;
