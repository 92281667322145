type Name = { name: string };

const nameSorter = (a: Name, b: Name) => {
  const nameA = a.name.toUpperCase();
  const nameB = b.name.toUpperCase();

  if (nameA < nameB) {
    return -1;
  }

  if (nameA > nameB) {
    return 1;
  }

  return 0;
};

const getAge = (birth_date: string) => {
  return (
    Math.abs(
      new Date(
        Date.now() - (Date.parse(birth_date) + 14 * 24 * 60 * 60 * 1000)
      ).getFullYear() - 1970
    ) || ""
  );
};

export { nameSorter, getAge };
