import { PropsWithChildren, useState } from "react";
import { User } from "types";
import { getShareLink } from "utils/api";
import { descriptors, interests } from "../constants";
import UserPhoto from "./UserPhoto";
import Verified from "../static/images/verified.svg";

type Props = {
  user?: User;
};

const DisplayedUser = ({ user }: PropsWithChildren<Props>): JSX.Element => {
  const [link, setLink] = useState(null);

  if (!user) {
    return;
  }

  getShareLink(user.id).then(setLink);

  return (
    <div id="displayed-user">
      <div id="displayed-user-header">
        <h2 id="displayed-user-name">
          <a href={link || "#"}>{user.name + " "}</a>
          <span className="user-age"> {user.age || " "} </span>
        </h2>
        {user.verified && (
          <img
            className="displayed-user-badge"
            src={Verified}
            alt="Verified badge"
          ></img>
        )}
      </div>
      <div id="displayed-user-content">
        {user.city && <h4>{user.city}</h4>}
        {user.school && <h5>{user.school}</h5>}
        {user.job && <h5>{user.job}</h5>}
        <div id="displayed-user-bio">
          {user.bio?.split("\n").map((line, idx) => (
            <div key={idx}>{line}</div>
          ))}
        </div>
        {user.interests?.length > 0 && <h3>Interests</h3>}
        <div id="displayed-user-interests">
          {user.interests?.map((id) => {
            const interest = interests.find((i) => i.id === id);

            return (
              interest && (
                <div key={id} className="interest">
                  {interest.name}
                </div>
              )
            );
          })}
        </div>
        {user.descriptors?.length > 0 && <h3>Descriptors</h3>}
        <div id="displayed-user-descriptors">
          {user.descriptors?.map((i) => {
            const descriptor = descriptors.find((d) => d.id === i.id);
            const choice = descriptor?.choices.find((c) => c.id === i.choice);

            return (
              descriptor && (
                <div key={i.id} className="descriptor">
                  <div className="descriptor-icon">
                    <img src={descriptor?.icon} alt=""></img>
                  </div>
                  <div className="descriptor-value">{choice?.name}</div>
                </div>
              )
            );
          })}
        </div>
        <div id="spotify-container">
          {user.fav_song && (
            <div>
              <h3>Favourite song</h3>
              <div id="fav-song">
                {[user.fav_song].map((s) => `${s.title} by ${s.artists[0]}`)}
              </div>
            </div>
          )}
          {user.fav_artists?.length > 0 && (
            <div>
              <h3>Favourite artists</h3>
              <div id="fav-artists">{user.fav_artists.join(", ")}</div>
            </div>
          )}
        </div>
        {user && <h3>Photos</h3>}
        <div id="displayed-user-photos">
          {user.photos
            .filter((p) => p.file_name.endsWith(".jpg"))
            .map((p) => {
              return (
                <div className="displayed-user" key={p.file_name}>
                  <UserPhoto cid={p.file_name} />
                </div>
              );
            })}
        </div>
        {user.instagram?.length > 0 && <h3>Instagram</h3>}
        <div id="displayed-user-instagram-photos">
          {user.instagram?.map((p) => {
            return (
              <div className="displayed-user" key={p.file_name}>
                <UserPhoto cid={p.file_name} />{" "}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default DisplayedUser;
