import { PropsWithChildren, useEffect, useRef, useState } from "react";
import { Platform, Requirement, User } from "types";
import { interests, descriptors, logics } from "../constants";
import { getAge, nameSorter } from "utils";
import { getUsers } from "utils/api";
import CheckBox from "./CheckBox";

type Props = {
  showResults: (users: User[]) => void;
};

const SearchBar = ({ showResults }: PropsWithChildren<Props>): JSX.Element => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [advanced, setAdvanced] = useState(false);
  const [descriptorId, setdescriptorId] = useState(descriptors[0].id);
  const [reqs, setReqs] = useState(new Map());
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(0);

  // inputs
  const platformInput = useRef(null);
  const nameInput = useRef(null);
  const ageInput = useRef(null);
  const bioInput = useRef(null);
  const cityInput = useRef(null);
  const schoolInput = useRef(null);
  const companyInput = useRef(null);
  const jobTitleInput = useRef(null);
  const songInput = useRef(null);
  const artistInput = useRef(null);
  const interestInput = useRef(null);
  const descriptorInput = useRef(null);
  const descriptorChoiceInput = useRef(null);
  const logicInput = useRef(null);
  const customLogicInput = useRef(null);

  // buttons
  const searchBtn = useRef(null);
  const advancedBtn = useRef(null);
  const backBtn = useRef(null);
  const forwardBtn = useRef(null);

  // containers
  const filterContainer = useRef(null);
  const advancedSearch = useRef(null);

  const toggleAdvanced = () => {
    setAdvanced(!advanced);

    const nameCheckBox = document.getElementById("name-checkbox");

    if (!advanced) {
      advancedBtn.current.style.display = "block";
      nameCheckBox.style.display = "flex";
      advancedSearch.current.style.display = "block";
      filterContainer.current.style.flexDirection = "column";
    } else {
      advancedBtn.current.style.display = "inline-block";
      nameCheckBox.style.display = "none";
      advancedSearch.current.style.display = "none";
      filterContainer.current.style.flexDirection = "row";
    }
  };

  const onDescriptorSelected = () => {
    setdescriptorId(descriptorInput.current.value);
  };

  const onLogicInputSelected = () => {
    if (logicInput.current.value === "CUSTOM") {
      customLogicInput.current.style.display = "inline-block";
    } else {
      customLogicInput.current.style.display = "none";
    }
  };

  const isChecked = (req: string): boolean => {
    return reqs.get(req);
  };

  const toggleReq = (req: Requirement) => {
    reqs.set(Requirement[req], !isChecked(Requirement[req]));

    setReqs(reqs);
  };

  const search = (offset: number) => {
    let requirements = Array.from(reqs.keys()).filter((k) => reqs.get(k));
    let logic = logicInput.current.value;

    if (advanced) {
      if (logic === "CUSTOM") {
        logic = customLogicInput.current.value;
      } else {
        logic = requirements.map((_, idx) => idx).join(` ${logic} `);
      }
    } else {
      requirements = ["Name"];
    }

    if (requirements.length === 1) {
      logic = "0";
    }

    const ageInputValue = ageInput.current.value;

    if (ageInputValue) {
      if (Number(ageInputValue) < 18) {
        ageInput.current.value = 18;
        console.log(ageInput.current.value);
      }
      if (Number(ageInputValue) > 55) {
        ageInput.current.value = 55;
      }
    }

    const width = Math.floor(windowSize.width / (140 + 15));
    const height = Math.floor(windowSize.height / (200 + 15));
    const limit = width * height;

    setLimit(limit);

    const data = {
      pagination: {
        offset,
        limit,
      },
      requirements,
      logic,
      scheme: {
        platforms: [platformInput.current.value],
        age: Number(ageInput.current.value),
        bio: bioInput.current.value.trim(),
        name: nameInput.current.value.trim(),
        city: cityInput.current.value.trim(),
        schools: [schoolInput.current.value.trim()],
        workplaces: [companyInput.current.value.trim()],
        job_titles: [jobTitleInput.current.value.trim()],
        song: songInput.current.value.trim(),
        artists: [artistInput.current.value.trim()],
        interests: [interestInput.current.value],
        descriptors: [
          [descriptorId, descriptorChoiceInput.current.value, null],
        ],
      },
    };

    getUsers(data).then((res) => {
      if (res.error) {
        return res.error;
      }

      const users = res.results
        .map((u: User) => ({ ...u, age: getAge(u.birth_date) }))
        .sort((a: User, b: User) => nameSorter(a, b) * 100 + a.age - b.age);

      backBtn.current.style.display = offset === 0 ? "none" : "inline-block";
      forwardBtn.current.style.display =
        users.length < limit ? "none" : "inline-block";

      showResults(users);
    });

    document.getElementById("app-root").style.height = "auto";
  };

  useEffect(() => {
    Array.from(document.getElementsByTagName("input")).forEach(
      (elem) => (elem.autocomplete = "off")
    );

    nameInput.current.addEventListener("keypress", (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        setOffset(0);
        search(0);
      }
    });

    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return (
    <div id="filter-container" ref={filterContainer}>
      <div id="filter-input-container">
        <div className="input-container">
          <CheckBox
            id="name-checkbox"
            action={() => toggleReq(Requirement.Name)}
          />
          <input
            type="text"
            ref={nameInput}
            id="name-input"
            placeholder="Name"
          ></input>
        </div>

        <div id="advanced-search" ref={advancedSearch}>
          <div className="input-container">
            <CheckBox
              id="platform-checkbox"
              action={() => toggleReq(Requirement.Platform)}
            />
            <label>Platform</label>
            <select ref={platformInput}>
              {Object.values(Platform)
                .filter((key) => typeof key === "string")
                .map((platform) => (
                  <option key={platform} value={platform}>
                    {platform}
                  </option>
                ))}
            </select>
          </div>

          <div className="input-container">
            <CheckBox action={() => toggleReq(Requirement.Verified)} />
            <label>Verified</label>
          </div>

          <div className="input-container">
            <CheckBox action={() => toggleReq(Requirement.Instagram)} />
            <label>Instagram</label>
          </div>

          <div className="input-container">
            <CheckBox action={() => toggleReq(Requirement.Age)} />
            <label>Age </label>
            <input
              type="number"
              ref={ageInput}
              min="18"
              max="55"
              defaultValue="18"
            ></input>
          </div>

          <div className="input-container">
            <CheckBox action={() => toggleReq(Requirement.Bio)} />
            <input type="text" ref={bioInput} placeholder="Bio"></input>
          </div>

          <div className="input-container">
            <CheckBox action={() => toggleReq(Requirement.City)} />
            <input type="text" ref={cityInput} placeholder="City"></input>
          </div>

          <div className="input-container">
            <CheckBox action={() => toggleReq(Requirement.School)} />
            <input type="text" ref={schoolInput} placeholder="Schools"></input>
          </div>

          <div className="input-container">
            <CheckBox action={() => toggleReq(Requirement.Workplace)} />
            <input
              type="text"
              ref={companyInput}
              placeholder="Companies"
            ></input>
          </div>

          <div className="input-container">
            <CheckBox action={() => toggleReq(Requirement.JobTitle)} />
            <input
              type="text"
              ref={jobTitleInput}
              placeholder="Job titles"
            ></input>
          </div>

          <div className="input-container">
            <CheckBox action={() => toggleReq(Requirement.FavouriteSong)} />
            <input
              type="text"
              ref={songInput}
              placeholder="Favourite song"
            ></input>
          </div>

          <div className="input-container">
            <CheckBox action={() => toggleReq(Requirement.FavouriteArtists)} />
            <input
              type="text"
              ref={artistInput}
              placeholder="Favourite artists"
            ></input>
          </div>

          <div className="input-container">
            <CheckBox action={() => toggleReq(Requirement.Interests)} />
            <label>Interest</label>
            <select ref={interestInput}>
              {interests.sort(nameSorter).map((interest) => (
                <option key={interest.id} value={interest.id}>
                  {interest.name}
                </option>
              ))}
            </select>
          </div>

          <div className="input-container">
            <CheckBox action={() => toggleReq(Requirement.Descriptors)} />
            <label>Descriptor</label>
            <select ref={descriptorInput} onChange={onDescriptorSelected}>
              {descriptors.map((descriptor) => (
                <option key={descriptor.id} value={descriptor.id}>
                  {descriptor.name}
                </option>
              ))}
            </select>
            <select ref={descriptorChoiceInput}>
              {descriptors
                .find((d) => d.id === descriptorId)
                .choices.map((choice) => (
                  <option key={choice.id} value={choice.id}>
                    {choice.name}
                  </option>
                ))}
            </select>
          </div>

          <div className="input-container">
            <label>Logic</label>
            <select ref={logicInput} onChange={onLogicInputSelected}>
              {logics.map((l) => (
                <option key={l.value} value={l.value}>
                  {l.name}
                </option>
              ))}
            </select>
            <input
              type="text"
              id="custom-logic"
              ref={customLogicInput}
              placeholder="Custom logic, eg.: 0 AND (1 OR 2)"
            ></input>
          </div>
        </div>
      </div>
      <div id="search-btn-container">
        {" "}
        <button
          id="search-btn"
          ref={searchBtn}
          onClick={() => {
            setOffset(0);
            search(0);
          }}
        >
          Search
        </button>
        <button id="advanced-btn" ref={advancedBtn} onClick={toggleAdvanced}>
          Pro
        </button>
        <div id="navigation-container">
          <div
            id="back-btn"
            className="arrow left"
            ref={backBtn}
            onClick={() => {
              let _offset = offset - limit;

              if (_offset < 0) {
                _offset = 0;
              }

              setOffset(_offset);
              search(_offset);
            }}
          ></div>
          <div
            id="forward-btn"
            className="arrow right"
            ref={forwardBtn}
            onClick={() => {
              const _offset = offset + limit;

              setOffset(_offset);
              search(_offset);
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
