import { FilterByReqBody } from "types";
import { API_URL } from "../constants";

const getUsers = async (reqs: FilterByReqBody) => {
  const request = new Request(`${API_URL}/getFilteredByReq`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(reqs),
  });

  const res = await fetch(request);

  return res.json();
};

const getShareLink = async (id: string) => {
  const request = new Request(`${API_URL}/getShareLink/${id}`);

  let res = await fetch(request);

  return res.json();
};

export { getUsers, getShareLink };
